var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { closable: "", "modal-class": "asterix-modal-size h-full" },
    on: {
      submit: function ($event) {
        return _vm.$emit("submit", $event)
      },
      cancel: _vm.closeModal,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [_vm._t("title")]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function () {
            return [
              _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    { staticClass: "w-full", attrs: { text: "Title" } },
                    [
                      _c("asterix-input", {
                        attrs: {
                          id: "name",
                          type: "text",
                          name: "name",
                          "validate-on-blur": "",
                          minlength: 3,
                          maxlength: 50,
                          placeholder: "My amazing creative",
                          required: "required",
                        },
                        model: {
                          value: _vm.creative.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.creative, "name", $$v)
                          },
                          expression: "creative.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "form-row",
                [
                  _c(
                    "sun-label-group",
                    {
                      staticClass: "w-full",
                      attrs: { text: "Feed Parser Provider" },
                    },
                    [
                      _c("sun-select", {
                        attrs: {
                          id: "feedId",
                          options: _vm.allFeeds,
                          "track-by": "id",
                          label: "name",
                          "color-tag": "gray",
                          disabled: _vm.isLoading || !_vm.isNewCreative,
                          required: true,
                          "text-error": "Select option",
                        },
                        on: { change: _vm.feedIdChange },
                        model: {
                          value: _vm.selectedFeedParser,
                          callback: function ($$v) {
                            _vm.selectedFeedParser = $$v
                          },
                          expression: "selectedFeedParser",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("creative-modal-footer", {
                attrs: { loading: _vm.loading },
                on: { cancel: _vm.closeModal },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }