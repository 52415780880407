<template>
  <asterix-form-modal
    closable
    modal-class="asterix-modal-size h-full"
    @submit="$emit('submit', $event)"
    @cancel="closeModal"
  >
    <template #title><slot name="title"></slot></template>
    <template #content>
      <form-row>
        <sun-label-group class="w-full" text="Title">
          <asterix-input
            id="name"
            v-model="creative.name"
            type="text"
            name="name"
            validate-on-blur
            :minlength="3"
            :maxlength="50"
            placeholder="My amazing creative"
            required="required"
          />
        </sun-label-group>
      </form-row>
      <form-row>
        <sun-label-group class="w-full" text="Feed Parser Provider">
          <sun-select
            id="feedId"
            v-model="selectedFeedParser"
            :options="allFeeds"
            track-by="id"
            label="name"
            color-tag="gray"
            :disabled="isLoading || !isNewCreative"
            :required="true"
            text-error="Select option"
            @change="feedIdChange"
          />
        </sun-label-group>
      </form-row>
    </template>
    <template #footer>
      <creative-modal-footer :loading="loading" @cancel="closeModal" />
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CONTEXT_NS, ACTIVE_CLIENT_KEY } from '@/store/modules/context/keys';
import AsterixInput from '@/components/atoms/AsterixInput';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import CreativeFeed from '@/model/modules/ecommerce/offer/creative/creativeFeed/CreativeFeed';
import CREATIVE_TYPES from '@/model/modules/ecommerce/offer/creative/CreativeTypes';
import FormRow from '@/components/atoms/FormRow/FormRow';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { getFeeds } from '@/services/modules/ecommerce/feed';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'CreativeFeedModal',
  components: {
    AsterixInput,
    CreativeModalFooter: () => import('@/components/molecules/shared/AsterixFooterModal'),
    AsterixFormModal,
    FormRow,
  },
  props: {
    /** @type CreativeFeed */
    value: {
      type: Object,
      default: () => new CreativeFeed(),
      validator: crea => {
        return crea.type.id === CREATIVE_TYPES.FEED.id;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getFeeds,
      allFeeds: [],
      selectedFeedParser: null,
      creative: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(CONTEXT_NS, {
      activeClient: ACTIVE_CLIENT_KEY,
    }),
    isNewCreative() {
      return !this.creative.id;
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newCrea) {
        this.creative = newCrea;
      },
    },
    creative: {
      deep: true,
      handler(newCrea) {
        this.$emit('input', newCrea);
      },
    },
  },
  async created() {
    this.creative = this.value;
    await this.loadFeeds();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async loadFeeds() {
      this.isLoading = true;
      try {
        const { data } = await this.getFeeds(new QueryParamsBuilder(1, 500), this.activeClient.id);
        this.allFeeds = data;
        this.selectedFeedParser = this.allFeeds.find(feed => feed.id === this.creative.feedId);
      } catch (e) {
        this.createToast(Toast.error('We cant get feedProviders', e.message));
      }

      this.isLoading = false;
    },
    closeModal() {
      this.$emit('cancel');
    },
    feedIdChange({ value }) {
      this.creative.feedId = value.id;
    },
  },
};
</script>
